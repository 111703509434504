/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  getOrientation,
  resetOrientation,
  checkImage,
  fetchApi
} from "../../../utils/methods";
import { ImageUrl, imageErrorMessage } from "../../../utils/constants";
import { withTranslation } from "react-i18next";
import {
  QueAndCard,
} from "./style";
import { Main } from "../../AdminTeamCreation/styles";
import HoverButton from "../../common/HoverButton";
import {
  addQueIcon,
  likertScaleIcon,
  mcqIcon,
  opinionScaleIcon,
  reorderIcon,
  shortDescIcon,
  yesnoIcon,
  Arrows,
  LaunchEventIcon,
  NextArrows,
  UploadIcon,
  closeIcon,
} from "../../../utils/icons";
import {
  DeleteQueAnsV2,
  EditQuestionAnswersV2,
  EditSurveyLibraryV2,
  GetSurveyCategories,
  GetSurveyLibraryDetails,
  GetSurveyQuestionTypes,
  ReorderQueV2,
} from "../../../redux/constants/apiConstants";
// import DropDown from "../../common/CommonDropDown/DropDown";
import CommonDropdown from "../../common/CommonDropDown/CommonDropdown";
import { Border } from "../../Journeys/styles";
import { ToggleButton } from "../../Recognition/AddCoreValues/styles";
import { withRouter } from "react-router-dom";
import QuestionReorderPopUp from "./QuestionReorderPopUp";
import CommonButton from "../../common/CommonButton/CommonButton";
import {
  ChallengeHeading,
  ButtonContainer,
  Layout,
  Container,
  InputContainer,
  StyledLabel,
  StyledInputV2,
  UploadPhotoContainer,
  RemoveImageV2,
  InputImage,
  EditorContainer,
} from '../../CreateEvent/style';
import Editor from "../../CKEditor";
import StepNames from "../../CreateEvent/stepNames";
import SkeletonLoder from "../../common/skeletonLoder";
import DynamicStyledTextArea from "./DynamicStyledTextArea";

const opinionpoints = [
  { value: 0, text: "0pt" },
  { value: 10, text: "10pts" },
];

const likeartpoints = [
  { value: 1, text: "1pt" },
  { value: 5, text: "5pts" },
];

const likertScaleValues = [
  { answer: "Strongly Agree", points: 5 },
  { answer: "Agree", points: 4 },
  { answer: "Neutral", points: 3 },
  { answer: "Disagree", points: 2 },
  { answer: "Strongly Disagree", points: 1 },
];

const opinionScaleValues = [
  { answer: "", points: 0 },
  { answer: "", points: 1 },
  { answer: "", points: 2 },
  { answer: "", points: 3 },
  { answer: "", points: 4 },
  { answer: "", points: 5 },
  { answer: "", points: 6 },
  { answer: "", points: 7 },
  { answer: "", points: 8 },
  { answer: "", points: 9 },
  { answer: "", points: 10 },
];

class EditSurveyLibrary extends Component {
  constructor(props) {
    super(props);
    this.targetDivRef = React.createRef();
    this.state = {
      imgSrc: "",
      title: "",
      description: "",
      imageName: "",
      step: 1,
      buttonClickScrollTop: false,
      imageUpdate: 0,
      surveyCategories: [],
      selectedSurveyCategory: null,
      selectedSurveyValue: null,
      companyId: props.companyInfo["id"],
      createdSurveyDetails: {},
      surveyQuestionTypes: [],
      questionAnswers: [
        {
          questionType: {},
          question: "",
          questionId: "",
          isFollowUp: false,
          followUpQue: "",
          isMcqWeightage: false,
          multipleMcqResponses: false,
          mcqDropDownOptions: [
            { value: 1, text: "1pt" }
          ],
        },
      ],
      mcqpoints: [
        { value: 1, text: "1pt" },
        { value: 2, text: "2pts" },
      ],
      showQueReorderPopUp: false,
      disable: false,
      messageToEmployeeCharacterCount: 0,
      companyInfo:null,
    };
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;

    const { match} = this.props;
    this.fetchSurveyLibraryDetails(match.params.id);

    this.fetchSurveyCategories();
    this.fetchQuestionTypes();
    this.setState({
      companyId: this.props.companyDetails?.id,
      libraryId:match.params.id
    })
  }


  componentWillUnmount() {
    this._isMounted = false;

  }

  componentDidUpdate() {
    if (this.state.buttonClickScrollTop) {
      window.scrollTo(0, 0);
      window.setTimeout(() => {
        this.setState({ buttonClickScrollTop: false });
      }, 2000);
    }
  }


  fetchSurveyLibraryDetails = async (id, updateQuestions) => {
    this.setState({loading:true});
    let apiUrl = GetSurveyLibraryDetails + `/${id}`;
    try {
      const res = await fetchApi(apiUrl , "GET");
      console.log("res",res)
      if (res?.status === "success") {

        const{title,description,image,library_questions,category_id,category_name}=res.data;
        if(!updateQuestions){
          this.setState({title:title,description:description,imgSrc:image,imageName:image,selectedSurveyValue:category_id,selectedSurveyCategory:category_name});
        }

        this.setQuestionAnswers(library_questions);
        
        this.setState({loading:false});

      } else {
        toast.error(res?.error);
      }
    } catch (error) {
      window.console.log(error);
    }

  }

  setQuestionAnswers = (data) => {
    console.log("data", data);
  
    const questionAns = data.map((item) => {
      const mcqOptions = [{ text: "1 pt", value: 1 }];
  
      // Normalize question type name
      let modifiedName = item.question_type_name.replace(/-/g, " ");
      if (modifiedName === "yes no") modifiedName = "yes or no";
      if (modifiedName === "short answer") modifiedName = "short description";
  
      const queAnsObj = {
        questionId: item.id,
        followUpQue: item.follow_up_question,
        isFollowUp: !!item.follow_up_question_id,
        questionType: {
          id: item.question_type_id,
          name: item.question_type_name,
          text: modifiedName,
        },
        question: item.question,
        answerId: item.question_answers?.[0]?.id,
        multipleMcqResponses: !!item.accept_multiple_responses,
        answers: [],
        isupdated: false,
      };
  
      // Process answers
      if (item.question_answers?.length > 0) {
        if (item.question_type_name === "likert-scale") {
          const isAscending = item.question_answers[0].points === 1;
          queAnsObj.answers = [
            { label: "Strongly Agree", points: isAscending ? 1 : 5, text: `${isAscending ? 1 : 5}pt${isAscending ? "" : "s"}` },
            { label: "Strongly Disagree", points: isAscending ? 5 : 1, text: `${isAscending ? 5 : 1}pt${isAscending ? "s" : ""}` },
          ];
        } else if (item.question_type_name === "opinion-scale" && item.question_answers.length > 10) {
          const isZeroPoints = item.question_answers[0].points === 0;
          queAnsObj.answers = [
            {
              label: item.question_answers[isZeroPoints ? 0 : 10].answer,
              points: item.question_answers[isZeroPoints ? 0 : 10].points,
              text: `${isZeroPoints ? 1 : 10}pt${isZeroPoints ? "" : "s"}`,
            },
            {
              label: item.question_answers[isZeroPoints ? 10 : 0].answer,
              points: item.question_answers[isZeroPoints ? 10 : 0].points,
              text: `${isZeroPoints ? 10 : 1}pt${isZeroPoints ? "s" : ""}`,
            },
          ];
        } else {
          queAnsObj.answers = item.question_answers.map((answer) => {
            const ansObj = {
              points: answer.points,
              label: answer.answer,
              text: `${answer.points}pt${answer.points > 1 ? "s" : ""}`,
            };
            if (item.question_type_name === "multiple-choice" && answer.points) {
              queAnsObj.isMcqWeightage = true;
              queAnsObj.mcqDropDownOptions = mcqOptions;
            }
            return ansObj;
          });
        }
      }
  
      console.log("queAnsObj", queAnsObj);
      return queAnsObj;
    });
  
    console.log("questionAns", questionAns);
  
    this.setState({ questionAnswers: questionAns }, () => {
      console.log("questionAnswers", this.state.questionAnswers);
      // Uncomment if needed
      // this.areAllValuesNotEmpty(this.state.questionAnswers);
    });
  };
  



  fetchSurveyCategories = async () => {
    let apiUrl = GetSurveyCategories;
    try {
      const res = await fetchApi(apiUrl , "GET");
      if (res?.categories) {
        this.setState({surveyCategories: res?.categories})

      } else {
        toast.error(res?.error);
      }
    } catch (error) {
      window.console.log(error);
    }

  }

  fetchQuestionTypes = async () => {
    let apiUrl = GetSurveyQuestionTypes;
    try {
      const res = await fetchApi(apiUrl , "GET");
      if (res?.["question-types"]) {
        let questionTypes = res["question-types"];
        let modifiedQuestionTypes = questionTypes.map((questionType) => {
          let modifiedName = questionType.name.replace(/-/g, " "); // Remove hyphens
          if (modifiedName === "yes no") {
            modifiedName = "yes or no"; // If name is "yes-no", set it to "yes"
          } else if (modifiedName === "short answer") {
            modifiedName = "short description";
          }
          return {
            id: questionType.id,
            text: modifiedName,
            name: questionType.name,
          };
        });

        this.setState({surveyQuestionTypes: modifiedQuestionTypes,  
        //   questionAnswers: [{questionType: modifiedQuestionTypes[0],question: "",isFollowUp: false,}]
        })

      } else {
        toast.error(res?.error);
      }
    } catch (error) {
      window.console.log(error);
    }

  }

  onChange = (e) => {
    let file = e.target.files[0];
    let fileArr = e.target.files;
    if (e.target.files[0]) {
      if (checkImage(fileArr)) {
        if (file?.size / 1000000 <= 1) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const array = document
            .getElementById("event-upload-file")
            .value.split("\\");
          reader.onloadend = () => {
            this.setState({
              imgSrc: reader.result,
              imageName: array[array.length - 1],
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  imgSrc: baseImage,
                  imageUpdate: 1,
                });
              });
            });
          };
        } else {
          toast.error("Please select image file less than 1MB");
          document.getElementById("event-upload-file").value = "";
        }
      } else {
        toast.error(imageErrorMessage);
        document.getElementById("event-upload-file").value = "";
      }
    }
  };

  onChangeInput = (e) => {
    if (e.target.value.charAt(0) !== " ") {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  removePhoto = () => {
    this.setState({
      imgSrc: "",
      imageName: "",
    });
  };


  onChangeDescription = (evt) => {
    const newContent = evt.getData();
    const trimmedString = newContent.replace(/\s|&nbsp;/g, "");
    trimmedString.length === 0 ||
    trimmedString.length === 7 ||
    trimmedString == "<p></p><p></p>"
      ? this.setState({ description: "" })
      : this.setState({ description: newContent });
  };

  updateStep = (value) => {
    this.setState({ step: value, buttonClickScrollTop: true });
  };


  close = () => (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_14978_75731"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <rect width={24} height={24} fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_14978_75731)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3605 0.678084C22.6358 -0.0466026 21.4608 -0.0466023 20.7361 0.678084L11.9067 9.50756L3.07795 0.678849C2.4145 0.0154025 1.33884 0.0154032 0.675399 0.678849C0.0119522 1.3423 0.0119521 2.41796 0.675399 3.0814L9.50411 11.9101L0.675642 20.7386C-0.0490441 21.4633 -0.0490441 22.6382 0.675643 23.3629C1.40033 24.0876 2.57528 24.0876 3.29996 23.3629L12.1284 14.5344L20.9577 23.3637C21.6211 24.0271 22.6968 24.0271 23.3602 23.3637C24.0237 22.7002 24.0237 21.6246 23.3602 20.9611L14.531 12.1319L23.3605 3.30241C24.0851 2.57772 24.0851 1.40277 23.3605 0.678084Z"
          fill="#9C9C9C"
        />
      </g>
    </svg>
  );

  renderHeading = () => (
    <ChallengeHeading color={"#005c87"}>
      <div style={{marginRight:"12px"}}>
        <HoverButton
          title={this.props.t("Close")}
          width="24px"
          height="24px"
          svgPath={this.close()}
          onClick={() => this.props.history.goBack()}
          activeColor={"#007AB1"}
          fillOpacity={1}
        />
      </div>
      {this.props.t("Edit survey library")}
    </ChallengeHeading>
  );

  stepOneFormData = () => {
    
    const {
      title,
      description,
      step,
      imgSrc
    } = this.state;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container color="#005C87">
            <div className="stepsNameHighlight">
              <StepNames stepCount={step} type="survey-library" />
            </div>
            <div className="form">
              <div className="heading">
                <div className="step" >{step}</div>
                <div className="headingName" style={{color:"#005c87"}}>{this.props.t("Survey Introduction")}</div>
              </div>
              <div className="formBody">
                <InputContainer>
                  <StyledLabel color={"#005c87"}>{this.props.t("Title of the survey")}{<span>*</span>}</StyledLabel>
                  <StyledInputV2
                    placeholder={"Write title here..."}
                    name="title"
                    onChange={this.onChangeInput}
                    value={title}
                    maxLength="100"
                    color="#005c87"
                    border={"1px solid #afcbd3"}
                  />
                </InputContainer>

                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/>

                <StyledLabel style={{marginTop:"0px"}} color={"#005c87"}>{this.props.t("Survey image")}{<span>*</span>}</StyledLabel>
                <UploadPhotoContainer>
                  <div className="innerDivImageContainer">
                    {this.state.imgSrc ? (
                      <RemoveImageV2 >
                        <img alt="profilre-img" src={this.checkBase64(imgSrc) ? imgSrc : `${ImageUrl}/${imgSrc}`}></img>
                        <div className="main-div" onClick={this.removePhoto}>
                          <div className="icon-div" ><div className="sub-icon-div">{closeIcon("white","8","8")}</div></div>
                        </div>
                      </RemoveImageV2>
                    ) : (
                      <div className="uploadImage">
                        <InputImage
                          className="edit-profile-pic "
                          imageReload={1}
                          style={{background:"none"}}
                        >
                          <div className="middle-section">
                            <div>{UploadIcon()}</div>
                            <div className="title">{this.props.t("Upload File")}</div>
                            <div className="sub-title">({this.props.t(".jpeg, .png")})</div>
                            
                          </div>
                          {/* <i className="addImg">{this.props.t("Upload Image")}</i> */}
                          <input
                            id="event-upload-file"
                            type="file"
                            name="user"
                            accept=".jpeg, .png, .jpg"
                            multiple={false}
                            onChange={(e) => this.onChange(e)}
                            onClick={(e) =>
                              e.target.files[0] && this.onChange(e)
                            }
                          />
                        </InputImage>
                      </div>
                    )}
                  </div>
                </UploadPhotoContainer>
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/>
                <InputContainer>
                  <StyledLabel style={{marginTop:"0px"}} color={"#005c87"}>{this.props.t("About the survey")}{<span>*</span>}</StyledLabel>
                  <EditorContainer>
                    <Editor
                      content={description}
                      onChange={this.onChangeDescription}
                    />
                  </EditorContainer>
                </InputContainer>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };


  stepTwoForm = () => {
    const {
      step,
      surveyQuestionTypes,
      questionAnswers,
      // mcqpoints,
      isActiveOrUpcoming,
      //   isFollowUp,
      selectedSurveyCategory,
      surveyCategories
    } = this.state;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container>
            <div className="stepsNameHighlight">
              <StepNames stepCount={step} type="survey-library" />
            </div>
            <div className="form">
              <div
                className="heading"
                style={{ justifyContent: "space-between" }}
              >
                <div style={{ display: "flex" }}>
                  <div className="step" style={{ background: "#005C87" }}>
                    {step}
                  </div>
                  <div
                    className="headingName"
                    style={{
                      color: "#005C87",
                      fontFamily: "Rubik-Medium",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "24px",
                    }}
                  >
                    How would you like to customize your survey?
                  </div>
                </div>
                {!isActiveOrUpcoming && (
                  <div style={{ display: "flex", marginRight: "15px" }}>
                    <HoverButton
                      title={"Add Question"}
                      svgPath={addQueIcon()}
                      onClick={this.scrollToDiv}
                    />
                    <HoverButton
                      title={"Reorder Questions"}
                      svgPath={reorderIcon()}
                      onClick={() =>
                        this.setState({ showQueReorderPopUp: true })
                      }
                    />
                  </div>
                )}
              </div>

              <CommonDropdown
                tileContainerStyle={{ width: "100%", padding:"0px 0px 20px 0px", margin: "20px 0 0 0" }}
                dropdownStyle={{ top: "95px" }}
                labelText={"Set survey category"}
                isRequired={true}
                dropdownOpen={this.state.so}
                placeholder={this.props.t("Select survey category")}
                title={
                  selectedSurveyCategory
                }
                id="dropdown-recurring"
                onClick={() => this.setState({ so: !this.state.so })}
                data={surveyCategories}
                active={selectedSurveyCategory?.id}
                onSelectParameter={["selectedSurveyCategory", "name"]}
                valueString={""}
                itemValue={true}
                valueIndex={1}
                labelMargin={"0px"}
                onSelect={this.onSelectCategory}
              />
              <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px', display:"flex"}}/>


              <div
                style={{
                  background: "white",
                  // height: "74px",
                  display: "flex",
                  alignItems: "center",
                  width:"100%",
                  marginBottom:"15px"
                }}
              >
                <span
                  style={{
                    color: "rgba(0, 92, 135, 0.60)",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                 Add atleast 5 question for better data generation. min. 2 questions are required
                </span>
              </div>

              <div className="formBody" style={{ padding: "0px" }}>
                {questionAnswers.length ? questionAnswers.map((item, index) => (
                  <React.Fragment key={index}>
                    {/* <div></div> */}
                    <QueAndCard>
                      <div className="header">
                        <div>
                          <span className="title">Question {index + 1}</span>
                        </div>
                        {!isActiveOrUpcoming && (
                          <div
                            onClick={() => {
                              this.deleteQuestionAns(index,item.questionId);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="delete-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M16.563 2.5h-3.438v-.625C13.125.839 12.285 0 11.25 0h-2.5C7.714 0 6.875.84 6.875 1.875V2.5H3.437c-.862 0-1.562.7-1.562 1.563v1.25c0 .345.28.625.625.625h15c.345 0 .625-.28.625-.625v-1.25c0-.863-.7-1.563-1.563-1.563Zm-8.438-.625c0-.345.28-.625.625-.625h2.5c.345 0 .625.28.625.625V2.5h-3.75v-.625ZM3.06 7.188c-.111 0-.2.093-.195.204l.516 10.822A1.873 1.873 0 0 0 5.254 20h9.491a1.873 1.873 0 0 0 1.873-1.786l.515-10.822a.195.195 0 0 0-.195-.205H3.061ZM12.5 8.75a.625.625 0 1 1 1.25 0v8.125a.625.625 0 1 1-1.25 0V8.75Zm-3.126 0a.625.625 0 1 1 1.25 0v8.125a.625.625 0 1 1-1.25 0V8.75Zm-3.125 0a.625.625 0 1 1 1.25 0v8.125a.625.625 0 1 1-1.25 0V8.75Z"
                                  fill="#005C87"
                                  fillOpacity=".6"
                                />
                              </svg>
                            </span>
                          </div>
                        )}
                      </div>
                      <Border
                        style={{
                          background: "rgba(0, 92, 135, 0.30)",
                          margin: "15px 0px",
                        }}
                      />
                      <CommonDropdown
                        data={surveyQuestionTypes}
                        label={"Your type of question"}
                        placeholder="Select type of question"
                        valueIndex={0}
                        dropdownStyle={{ top: "30px" }}
                        onSelectParameter={["text", "id"]}
                        itemValue={true}
                        activeValue={true}
                        title={item?.questionType?.text}
                        displayValue={true}
                        onClick={() => this.setState({ so: !this.state.so })}
                        valueString={""}
                        tileContainerStyle={{
                          width: "100%",
                          // background: "rgba(247, 245, 235, 0.50)",
                          padding: "0px",
                          // color:"#005C87"
                        }}
                        background={
                          isActiveOrUpcoming && "rgba(0, 92, 135, 0.05)"
                        }
                        active={item?.questionType?.id}
                        onSelect={(value) => {
                          this.onQueTypeSelect(index, value, item);
                        }}
                        disabled={isActiveOrUpcoming}
                        height={"60px"}
                        icon={
                          item?.questionType?.name == "short-answer" ? (
                            shortDescIcon()
                          ) :  item?.questionType?.name == "opinion-scale" ? (
                            opinionScaleIcon()
                          ) :  item?.questionType?.name == "multiple-choice" ? (
                            mcqIcon()
                          ) :  item?.questionType?.name == "yes-no" ? (
                            yesnoIcon()
                          ) :  item?.questionType?.name == "likert-scale" ? (
                            likertScaleIcon()
                          ) : (
                            <div
                              style={{
                                background: "white",
                                width: "40px",
                                height: "40px",
                                borderRadius: "3px",
                              }}
                            ></div>
                          )
                        }
                      />
                      <InputContainer>
                        <StyledLabel
                          style={{
                            color: "#005C87",
                            fontFamily: "Rubik-Regular",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "20px",
                          }}
                        >
                          What would you like to ask?{<span>*</span>}
                        </StyledLabel>
                        {/* <StyledInputV2
                          placeholder={"Enter the question you want ask"}
                          name="title"
                          onChange={(e) => this.onQueChange(index, e)}
                          value={item.question}
                          // maxLength="50"
                        /> */}
                        <DynamicStyledTextArea
                          key={index}
                          index={index}
                          placeholder={"Enter the question you want to ask"}
                          name={`question-${index}`}
                          onChange={this.onQueChange}
                          value={item.question}
                          maxLength={200}
                        />
                      </InputContainer>

                      { item?.questionType?.name === "opinion-scale" ? (
                        <div className="opinion-scale">
                          <div className="header">
                            <span
                              className="title"
                              style={{ marginTop: "0px" }}
                            >
                              10 Point Scale
                            </span>
                          </div>
                          <div className="point-scale">
                            <div className="point-label">
                              <div
                                style={{
                                  color: "rgba(0, 92, 135, 0.60)",
                                  fontFamily: "Rubik-Regular",
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                }}
                              >{`"0" label`}</div>
                              <div className="input-label">
                                <InputContainer>
                                  <StyledInputV2
                                    placeholder={"eg. it was bad"}
                                    name="title"
                                    onChange={(e) =>
                                      this.onOpinionLabelChange(index, e, 0)
                                    }
                                    value={
                                      item?.answers?.[0]?.label
                                    }
                                    maxLength="50"
                                    style={{
                                      // background: "rgba(247, 245, 235, 0.50)",
                                      border:
                                        "1px solid rgba(0, 92, 135, 0.30)",
                                      color: isActiveOrUpcoming
                                        ? "rgba(0, 92, 135,0.6)"
                                        : "rgba(0, 92, 135)",
                                      fontFamily: "Rubik-Medium",
                                      fontSize: "16px",
                                      fontWeight: 500,
                                      lineHeight: "20px",
                                    }}
                                    background={
                                      isActiveOrUpcoming &&
                                      "rgba(0, 92, 135, 0.05)"
                                    }
                                    disabled={isActiveOrUpcoming}
                                  />
                                </InputContainer>
                              </div>
                            </div>
                            <div className="point-dropdown">
                              <CommonDropdown
                                data={opinionpoints}
                                // label={"Your type of question"}
                                placeholder="Select points"
                                valueIndex={0}
                                dropdownStyle={{ top: "30px" }}
                                onSelectParameter={["text", "id"]}
                                itemValue={true}
                                activeValue={true}
                                title={
                                  item?.answers?.[0]?.text
                                }
                                displayValue={true}
                                valueString={""}
                                tileContainerStyle={{
                                  width: "110px",
                                  // background: "#F7F5EB",
                                  padding: "0px",
                                  marginRight: "25px",
                                }}
                                background={
                                  isActiveOrUpcoming && "rgba(0, 92, 135, 0.05)"
                                }
                                disabled={isActiveOrUpcoming}
                                active={
                                  item?.answers?.[0]?.points
                                }
                                onSelect={(value) => {
                                  this.onOpinionPointChange(index, value, 0);
                                }}
                                onClick={() => this.setState({ so: !this.state.so })}
                                height={"60px"}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                margin: "20px 25px 0px 25px",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="44"
                                height="18"
                                viewBox="0 0 44 18"
                                fill="none"
                              >
                                <rect
                                  y="6"
                                  width="44"
                                  height="6"
                                  rx="3"
                                  fill="#005C87"
                                />
                                <circle
                                  cx="22"
                                  cy="9"
                                  r="8.5"
                                  fill="#F4AAA9"
                                  stroke="#fff"
                                />
                                <path
                                  d="M20 7h4m-6 2h8m-6 2h4"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>

                            <div className="point-label">
                              <div
                                style={{
                                  color: "rgba(0, 92, 135)",
                                  fontFamily: "Rubik-Regular",
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  lineHeight: "20px",
                                }}
                              >{`"10" label`}</div>
                              <div className="input-label">
                                <InputContainer>
                                  <StyledInputV2
                                    placeholder={"eg. it was Perfect"}
                                    name="title"
                                    onChange={(e) =>
                                      this.onOpinionLabelChange(index, e, 1)
                                    }
                                    value={
                                      item?.answers?.[1]?.label
                                    }
                                    maxLength="50"
                                    style={{
                                      // background: "rgba(247, 245, 235, 0.50)",
                                      border:
                                        "1px solid rgba(0, 92, 135, 0.30)",
                                      color: isActiveOrUpcoming
                                        ? "rgba(0, 92, 135,0.6)"
                                        : "rgba(0, 92, 135)",
                                      fontFamily: "Rubik-Medium",
                                      fontSize: "16px",
                                      fontWeight: 500,
                                      lineHeight: "20px",
                                    }}
                                    background={
                                      isActiveOrUpcoming &&
                                      "rgba(0, 92, 135, 0.05)"
                                    }
                                    disabled={isActiveOrUpcoming}
                                  />
                                </InputContainer>
                              </div>
                            </div>

                            <div className="point-dropdown">
                              <CommonDropdown
                                data={opinionpoints}
                                // label={"Your type of question"}
                                placeholder="Select points"
                                valueIndex={0}
                                dropdownStyle={{ top: "30px" }}
                                onSelectParameter={["text", "value"]}
                                itemValue={true}
                                activeValue={true}
                                title={
                                  item?.answers?.[1].text
                                }
                                displayValue={true}
                                valueString={""}
                                tileContainerStyle={{
                                  width: "110px",
                                  // background: "#F7F5EB",
                                  padding: "0px",
                                  marginRight: "25px",
                                }}
                                background={
                                  isActiveOrUpcoming && "rgba(0, 92, 135, 0.05)"
                                }
                                disabled={isActiveOrUpcoming}
                                active={
                                  item &&
                                  item.answers &&
                                  item.answers.length &&
                                  item.answers[1] &&
                                  item.answers[1].points
                                }
                                onSelect={(value) => {
                                  this.onOpinionPointChange(index, value, 1);
                                }}
                                onClick={() => this.setState({ so: !this.state.so })}
                                height={"60px"}
                              />
                            </div>
                          </div>
                        </div>
                      ) :  item?.questionType?.name === "likert-scale" ? (
                        <div className="opinion-scale">
                          <div className="header">
                            <span className="title">5 Point Scale</span>
                          </div>
                          <div className="point-scale">
                            <div
                              className="point-label"
                              style={{ width: "auto" }}
                            >
                              <div
                                className="input-label"
                                style={{ width: "200px" }}
                              >
                                <InputContainer>
                                  <StyledInputV2
                                    placeholder={""}
                                    name="title"
                                    readOnly
                                    onChange={(e) =>
                                      this.onLabelChange(index, e, 0, "answers")
                                    }
                                    value={
                                      item &&
                                      item.answers &&
                                      item.answers.length &&
                                      item.answers[0] &&
                                      item.answers[0].label
                                    }
                                    maxLength="50"
                                    style={{
                                      background: "rgba(0, 92, 135, 0.05)",
                                      border:
                                        "1px solid rgba(0, 92, 135, 0.30)",
                                      width: "200px",
                                      color: "rgba(0, 92, 135, 0.60)",
                                      fontFamily: "Rubik-Medium",
                                      fontSize: "16px",
                                      fontWeight: 500,
                                      lineHeight: "20px",
                                    }}
                                  />
                                </InputContainer>
                              </div>
                            </div>
                            <div
                              className="point-dropdown"
                              style={{ marginTop: "0px" }}
                            >
                              <CommonDropdown
                                data={likeartpoints}
                                // label={"Your type of question"}
                                placeholder="Select points"
                                valueIndex={0}
                                dropdownStyle={{ top: "30px" }}
                                onSelectParameter={["text", "value"]}
                                itemValue={true}
                                activeValue={true}
                                title={
                                  item &&
                                  item.answers &&
                                  item.answers.length &&
                                  item.answers[0] &&
                                  item.answers[0].text
                                }
                                displayValue={true}
                                valueString={""}
                                tileContainerStyle={{
                                  width: "110px",
                                  // background: "#F7F5EB",
                                  padding: "0px",
                                  marginRight: "25px",
                                }}
                                active={
                                  item &&
                                  item.answers &&
                                  item.answers.length &&
                                  item.answers[0] &&
                                  item.answers[0].points
                                }
                                onSelect={(value) => {
                                  this.onLikeartPointChange(
                                    index,
                                    value,
                                    0,
                                    "answers"
                                  );
                                }}
                                onClick={() => this.setState({ so: !this.state.so })}
                                height={"60px"}
                                background={
                                  isActiveOrUpcoming && "rgba(0, 92, 135, 0.05)"
                                }
                                disabled={isActiveOrUpcoming}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                margin: "0px 25px 0px 25px",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="44"
                                height="18"
                                viewBox="0 0 44 18"
                                fill="none"
                              >
                                <rect
                                  y="6"
                                  width="44"
                                  height="6"
                                  rx="3"
                                  fill="#005C87"
                                />
                                <circle
                                  cx="22"
                                  cy="9"
                                  r="8.5"
                                  fill="#F4AAA9"
                                  stroke="#fff"
                                />
                                <path
                                  d="M20 7h4m-6 2h8m-6 2h4"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>

                            <div
                              className="point-label"
                              style={{ width: "auto" }}
                            >
                              {/* <div>{`"${item.opinionScale[1].points}" label`}</div> */}
                              <div
                                className="input-label"
                                style={{ width: "200px" }}
                              >
                                <InputContainer>
                                  <StyledInputV2
                                    readOnly
                                    placeholder={""}
                                    name="title"
                                    onChange={(e) =>
                                      this.onLabelChange(index, e, 1, "answers")
                                    }
                                    value={
                                      item &&
                                      item.answers &&
                                      item.answers.length &&
                                      item.answers[1] &&
                                      item.answers[1].label
                                    }
                                    // maxLength="50"
                                    style={{
                                      background: "rgba(0, 92, 135, 0.05)",
                                      border:
                                        "1px solid rgba(0, 92, 135, 0.30)",
                                      width: "200px",
                                      color: "rgba(0, 92, 135, 0.60)",
                                      fontFamily: "Rubik-Medium",
                                      fontSize: "16px",
                                      fontWeight: 500,
                                      lineHeight: "20px",
                                    }}
                                  />
                                </InputContainer>
                              </div>
                            </div>

                            <div
                              className="point-dropdown"
                              style={{ marginTop: "0px" }}
                            >
                              <CommonDropdown
                                data={likeartpoints}
                                // label={"Your type of question"}
                                placeholder="Select points"
                                valueIndex={0}
                                dropdownStyle={{ top: "30px" }}
                                onSelectParameter={["text", "value"]}
                                itemValue={true}
                                activeValue={true}
                                title={
                                  item &&
                                  item.answers &&
                                  item.answers.length &&
                                  item.answers[1] &&
                                  item.answers[1].text
                                }
                                displayValue={true}
                                valueString={""}
                                tileContainerStyle={{
                                  width: "110px",
                                  // background: "#F7F5EB",
                                  padding: "0px",
                                  marginRight: "25px",
                                }}
                                active={
                                  item &&
                                  item.answers &&
                                  item.answers.length &&
                                  item.answers[1] &&
                                  item.answers[1].points
                                }
                                onSelect={(value) => {
                                  this.onLikeartPointChange(
                                    index,
                                    value,
                                    1,
                                    "answers"
                                  );
                                }}
                                height={"60px"}
                                background={
                                  isActiveOrUpcoming && "rgba(0, 92, 135, 0.05)"
                                }
                                disabled={isActiveOrUpcoming}
                                onClick={() => this.setState({ so: !this.state.so })}
                              />
                            </div>
                          </div>
                        </div>
                      ) :  item?.questionType?.name === "multiple-choice" ? (
                        <div className="opinion-scale">
                          <div className="header" style={{ marginTop: "15px" }}>
                            <span
                              className="title"
                              style={{ marginTop: "0px" }}
                            >
                              Options
                            </span>
                          </div>
                          {item &&
                            item.answers &&
                            item.answers.map((ans, ind) => (
                              <div key={ind} className="point-scale">
                                <div className="options">
                                  {String.fromCharCode(ind + 97)}
                                </div>
                                <div
                                  className="point-label"
                                  style={{ width: "300px" }}
                                >
                                  {/* <div>{`"${item.opinionScale[1].points}" label`}</div> */}
                                  <div className="input-label">
                                    <InputContainer>
                                      <StyledInputV2
                                        placeholder={"Add option"}
                                        name="title"
                                        onChange={(e) =>
                                          this.onLabelChange(
                                            index,
                                            e,
                                            ind,
                                            "answers"
                                          )
                                        }
                                        value={ans.label}
                                        maxLength="50"
                                        background={
                                          isActiveOrUpcoming &&
                                          "rgba(0, 92, 135, 0.05)"
                                        }
                                        disabled={isActiveOrUpcoming}
                                      />
                                    </InputContainer>
                                  </div>
                                </div>

                                {!item.multipleMcqResponses && (
                                  <div
                                    className="point-dropdown"
                                    style={{ marginTop: "0px" }}
                                  >
                                    <CommonDropdown
                                      data={item.mcqDropDownOptions}
                                      // label={"Your type of question"}
                                      placeholder="Select points"
                                      valueIndex={0}
                                      dropdownStyle={{ top: "30px" }}
                                      onSelectParameter={["text", "value"]}
                                      itemValue={true}
                                      activeValue={true}
                                      title={ans.text}
                                      displayValue={true}
                                      valueString={""}
                                      tileContainerStyle={{
                                        width: "110px",
                                        // background: "#F7F5EB",
                                        padding: "0px",
                                        marginRight: "25px",
                                      }}
                                      background={
                                        isActiveOrUpcoming &&
                                        "rgba(0, 92, 135, 0.05)"
                                      }
                                      disabled={isActiveOrUpcoming}
                                      active={ans.points}
                                      onSelect={(value) => {
                                        this.onMcqPointChange(
                                          index,
                                          value,
                                          ind,
                                          "answers"
                                        );
                                      }}
                                      height={"60px"}
                                      onClick={() => this.setState({ so: !this.state.so })}
                                    />
                                  </div>
                                )}

                                {item.answers.length > 2 &&
                                  !isActiveOrUpcoming && (
                                  <div
                                    onClick={() => {
                                      this.deleteMcqOption(item, index, ind);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "20px",
                                    }}
                                  >
                                    <span className="delete-icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <path
                                          d="M16.563 2.5h-3.438v-.625C13.125.839 12.285 0 11.25 0h-2.5C7.714 0 6.875.84 6.875 1.875V2.5H3.437c-.862 0-1.562.7-1.562 1.563v1.25c0 .345.28.625.625.625h15c.345 0 .625-.28.625-.625v-1.25c0-.863-.7-1.563-1.563-1.563Zm-8.438-.625c0-.345.28-.625.625-.625h2.5c.345 0 .625.28.625.625V2.5h-3.75v-.625ZM3.06 7.188c-.111 0-.2.093-.195.204l.516 10.822A1.873 1.873 0 0 0 5.254 20h9.491a1.873 1.873 0 0 0 1.873-1.786l.515-10.822a.195.195 0 0 0-.195-.205H3.061ZM12.5 8.75a.625.625 0 1 1 1.25 0v8.125a.625.625 0 1 1-1.25 0V8.75Zm-3.126 0a.625.625 0 1 1 1.25 0v8.125a.625.625 0 1 1-1.25 0V8.75Zm-3.125 0a.625.625 0 1 1 1.25 0v8.125a.625.625 0 1 1-1.25 0V8.75Z"
                                          fill="#005C87"
                                          fillOpacity=".6"
                                        />
                                      </svg>
                                    </span>
                                  </div>
                                )}
                              </div>
                            ))}

                          <div className="more-option">
                            <span
                              onClick={() =>
                                !isActiveOrUpcoming && this.addOptions(index)
                              }
                              style={{
                                color:
                                  isActiveOrUpcoming &&
                                  "rgba(0, 92, 135, 0.60)",
                                cursor: isActiveOrUpcoming && "default",
                              }}
                            >
                              Add More Option (Max 10)
                            </span>
                          </div>

                          <div className="toggle-btn">
                            <ToggleButton
                              style={{ position: "unset" }}
                              disabled={isActiveOrUpcoming}
                            >
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={item.multipleMcqResponses}
                                  onChange={() => {
                                    !isActiveOrUpcoming &&
                                      this.onFollowUpToggle(
                                        index,
                                        "multipleMcqResponses",
                                        item
                                      );
                                    // this.createSurveyQues(item, index);
                                  }}
                                  disabled={isActiveOrUpcoming}
                                />
                                <span className="slider round"></span>
                              </label>
                            </ToggleButton>
                            <div className="toggle-label">
                              Allow respondents to select multiple answers
                            </div>
                          </div>
                        </div>
                      ) :  item?.questionType?.name === "yes-no" ? (
                        <div
                          className="opinion-scale"
                          style={{ marginTop: "15px" }}
                        >
                          <div className="header" style={{ marginTop: "0px" }}>
                            <span
                              className="title"
                              style={{ marginTop: "0px" }}
                            >
                              Options
                            </span>
                          </div>

                          {item &&
                            item.answers &&
                            item.answers.map((ans, ind) => (
                              <div key={ind} className="point-scale">
                                <div className="options">
                                  {String.fromCharCode(ind + 97)}
                                </div>
                                <div
                                  className="point-label"
                                  style={{ width: "300px" }}
                                >
                                  {/* <div>{`"${item.opinionScale[1].points}" label`}</div> */}
                                  <div className="input-label">
                                    <InputContainer>
                                      <StyledInputV2
                                        placeholder={""}
                                        readOnly
                                        name="title"
                                        onChange={(e) =>
                                          this.onLabelChange(
                                            index,
                                            e,
                                            ind,
                                            "answers"
                                          )
                                        }
                                        value={ans.label}
                                        // maxLength="50"
                                        style={{
                                          background: "rgba(0, 92, 135, 0.05)",
                                          border:
                                            "1px solid rgba(0, 92, 135, 0.30)",
                                          color: "rgba(0, 92, 135, 0.60)",
                                          fontFamily: "Rubik-Medium",
                                          fontSize: "16px",
                                          fontWeight: 400,
                                          lineHeight: "20px",
                                        }}
                                      />
                                    </InputContainer>
                                  </div>
                                </div>

                                <div
                                  className="point-dropdown"
                                  style={{ marginTop: "0px" }}
                                >
                                  <CommonDropdown
                                    data={likeartpoints}
                                    // label={"Your type of question"}
                                    placeholder="Select points"
                                    valueIndex={0}
                                    dropdownStyle={{ top: "30px" }}
                                    onSelectParameter={["text", "value"]}
                                    itemValue={true}
                                    activeValue={true}
                                    title={ans.text}
                                    displayValue={true}
                                    valueString={""}
                                    tileContainerStyle={{
                                      width: "110px",
                                      // background: "rgba(247, 245, 235, 0.50)",
                                      padding: "0px",
                                      marginRight: "25px",
                                    }}
                                    active={ans.points}
                                    onSelect={(value) => {
                                      this.onLikeartPointChange(
                                        index,
                                        value,
                                        ind,
                                        "answers"
                                      );
                                    }}
                                    background={
                                      isActiveOrUpcoming &&
                                      "rgba(0, 92, 135, 0.05)"
                                    }
                                    disabled={isActiveOrUpcoming}
                                    height={"60px"}
                                    onClick={() => this.setState({ so: !this.state.so })}
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      ) : null}

                      {/* {item.questionType.name != "short-answer" && <div className="follow-up">
                        <div className="title">
                          <span>Would you like more detail?</span>
                        </div>
                        <div className="toggle-btn">
                          <ToggleButton style={{ position: "unset" }} disabled={isActiveOrUpcoming}>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={item.isFollowUp}
                                onChange={() => {
                                  this.onFollowUpToggle(index, "isFollowUp", item);
                                }}
                                disabled={isActiveOrUpcoming}
                              />
                              <span className="slider round"></span>
                            </label>
                          </ToggleButton>
                          <div className="toggle-label">
                            Ask follow-up question
                          </div>
                        </div>
                      </div>}

                      {(item.isFollowUp && item.questionType.name != "short-answer") && (
                        <div style={{ marginTop: "15px" }}>
                          <StyledInputV2
                            placeholder={"eg. What would you suggest?"}
                            name="title"
                            onChange={(e)=>{this.onFollowUpQueChange(index, e); }}
                            value={item.followUpQue}
                            // maxLength="50"
                            style={{
                              background: "rgba(247, 245, 235, 0.50)",
                              border: "1px solid rgba(0, 92, 135, 0.30)",
                              color: "#005C87",fontFamily: "Rubik-Medium",fontSize: "16px",fontWeight: 400,lineHeight: "24px"
                            }}
                          />
                        </div>
                      )} */}

                      <div className="save-buton"><button style={{opacity:(!this.validateSingleQuestionAnswers(index) || !item.isupdated)  && "0.5"}} disabled={!this.validateSingleQuestionAnswers(index) || !item.isupdated} className="save-btn" onClick={()=>this.createSurveyQues(item,index,item.isFollowUp)}>Update</button></div>
                    </QueAndCard>

                    <div
                      style={{ height: "25px", background: "white" }}
                    ></div>
                  </React.Fragment>
                )):null}

                {!isActiveOrUpcoming && (
                  <div className="addex" ref={this.targetDivRef}>
                    <button onClick={this.addNewQue}>+ Add New Question</button>
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  validateSingleQuestionAnswers = (index) => {
    const { questionAnswers } = this.state;
    const errors = [];
    let isValid = true;
  
    const question = questionAnswers[index];
    if (!question) {
      errors.push(`Question at index ${index} does not exist.`);
      return { isValid: false, errors };
    }
  
    // Validate question field
    if (!question.question || question.question.trim() === "") {
      isValid = false;
      errors.push(`Question at index ${index + 1} is empty.`);
    }
  
    // Validate answers if question type is not "short-answer"
    if (question.questionType?.name !== "short-answer") {
      question.answers.forEach((answer, ansIndex) => {
        const { label, points, text } = answer;
  
        if (!label || label.trim() === "") {
          isValid = false;
          errors.push(`Label is empty in answer ${ansIndex + 1} of question ${index + 1}.`);
        }
        if (points.toString().trim() === "") {
          isValid = false;
          errors.push(`Points are empty in answer ${ansIndex + 1} of question ${index + 1}.`);
        }
        if (!text || text.trim() === "") {
          isValid = false;
          errors.push(`Text is empty in answer ${ansIndex + 1} of question ${index + 1}.`);
        }
      });
    }
  
    // console.log("isValid", isValid);
    return isValid;
  };


  onQueTypeSelect = (index, value, item) => {
    const questionAnswers = [...this.state.questionAnswers];
    questionAnswers[index].questionType = value;
    questionAnswers[index].isupdated = true;
    if (value.name === "opinion-scale") {
      questionAnswers[index].answers = [
        { label: "", points: 0, text: "0pts" },
        { label: "", points: 10, text: "10pts" },
      ];
    } else if (value.name === "multiple-choice") {
      questionAnswers[index].answers = [
        { label: "", points: 1, text: "1pt" },
        { label: "", points: 1, text: "1pt" },
      ];
      questionAnswers[index].mcqDropDownOptions = [
        { value: 1, text: "1pt" }
      ];
      questionAnswers[index].isMcqWeightage = true;
      questionAnswers[index].multipleMcqResponses = false;
    } else if (value.name === "yes-no") {
      questionAnswers[index].answers = [
        { label: "Yes", points: 1, text: "1pt" },
        { label: "No", points: 5, text: "5pts" },
      ];
    } else if (value.name === "likert-scale") {
      questionAnswers[index].answers = [
        { label: "Strongly Agree", points: 5, text: "5pts" },
        { label: "Strongly Disagree", points: 1, text: "1pt" },
      ];
    }
    this.setState({ questionAnswers: questionAnswers });

    if (item.questionId) {
    //   this.createSurveyQues(item, index, item.isFollowUp);
    }
  };

  onQueChange = (index, event) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].question = event.target.value;
    questionAnswers[index].isupdated = true;
    this.setState({ questionAnswers: questionAnswers });
    this.areAllValuesNotEmpty(questionAnswers);
  };

  onFollowUpToggle = (index, name, /*item*/) => {
    const { questionAnswers } = this.state;
    questionAnswers[index][name] = !questionAnswers[index][name];
    questionAnswers[index].isupdated = true;
    this.setState({ questionAnswers: questionAnswers }, () => {
      if (name == "multipleMcqResponses") {

        if (questionAnswers[index]["multipleMcqResponses"]) {
          questionAnswers[index]["isMcqWeightage"] = false;
        } else {
          questionAnswers[index]["isMcqWeightage"] = true;

          // for (let i = 0; i < questionAnswers[index]["answers"].length; i++) {
          //   (questionAnswers[index]["answers"][i].text =
          //     i + 1 > 1 ? i + 1 + "pts" : i + 1 + "pt"), (questionAnswers[index]["answers"][i].points = i + 1);
          // }
        }
        this.setState({ questionAnswers: questionAnswers });
      } else if (name == "isMcqWeightage") {
        // this.onOpinionLabelBlur(index, "answers");
      }

    });
  };

  addNewQue = () => {
    let questionAnswers = [...this.state.questionAnswers];
    let allsurveyQuestionTypes = [...this.state.surveyQuestionTypes];
    let shortDesc = allsurveyQuestionTypes.filter(
      (item) => item.name === "short-answer"
    );
    questionAnswers.push({
      questionType: shortDesc[0],
      question: "",
      isFollowUp: false,
    });
    this.setState({ questionAnswers: questionAnswers }, () =>
      this.areAllValuesNotEmpty(this.state.questionAnswers)
    );
  };


  onFollowUpQueChange = (index, event) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].followUpQue = event.target.value;
    this.setState({ questionAnswers: questionAnswers });
  };


  onOpinionLabelChange = (index, e, labelIndex) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].answers[labelIndex].label = e.target.value;
    questionAnswers[index].isupdated = true;
    this.setState({ questionAnswers: questionAnswers });
  };

  onOpinionLabelBlur = (index, name) => {
    const { questionAnswers } = this.state;
    this.createSurveyAns(
      questionAnswers[index],
      index,
      questionAnswers[index][name]
    );
  };

  onOpinionPointChange = (index, value, labelIndex) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].isupdated = true;
    // questionAnswers[index].opinionScale[labelIndex].value = value.value;
    if (labelIndex === 0 && value.value === 0) {
      questionAnswers[index].answers[labelIndex].text = value.text;
      questionAnswers[index].answers[labelIndex].points = value.value;
      questionAnswers[index].answers[1].text = "10pts";
      questionAnswers[index].answers[1].points = 10;
    } else if (labelIndex === 0 && value.value === 10) {
      questionAnswers[index].answers[labelIndex].text = value.text;
      questionAnswers[index].answers[labelIndex].points = value.value;
      questionAnswers[index].answers[1].text = "0pts";
      questionAnswers[index].answers[1].points = 0;
    }
    if (labelIndex === 1 && value.value === 0) {
      questionAnswers[index].answers[labelIndex].text = value.text;
      questionAnswers[index].answers[labelIndex].points = value.value;
      questionAnswers[index].answers[0].text = "10pts";
      questionAnswers[index].answers[0].points = 10;
    } else if (labelIndex === 1 && value.value === 10) {
      questionAnswers[index].answers[labelIndex].text = value.text;
      questionAnswers[index].answers[labelIndex].points = value.value;
      questionAnswers[index].answers[0].text = "0pts";
      questionAnswers[index].answers[0].points = 0;
    }

    this.setState({ questionAnswers: questionAnswers });
  };

  onLabelChange = (index, e, labelIndex, name) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].isupdated = true;
    questionAnswers[index][name][labelIndex].label = e.target.value;
    this.setState({ questionAnswers: questionAnswers });
  };

  onLikeartPointChange = (index, value, labelIndex, name) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].isupdated = true;
    const oppositeIndex = labelIndex === 0 ? 1 : 0;
    const oppositePoints = value.value === 1 ? 5 : 1;
  
    questionAnswers[index][name][labelIndex].text = value.text;
    questionAnswers[index][name][labelIndex].points = value.value;
    questionAnswers[index][name][oppositeIndex].text = `${oppositePoints}pts`;
    questionAnswers[index][name][oppositeIndex].points = oppositePoints;
  
    this.setState({ questionAnswers });
  };

  onMcqPointChange = (index, value, labelIndex, name) => {
    const { questionAnswers } = this.state;
    questionAnswers[index].isupdated = true;

    questionAnswers[index][name][labelIndex].text = value.text;
    questionAnswers[index][name][labelIndex].points = value.value;

    this.setState({ questionAnswers: questionAnswers });
  };



  deleteMcqOption = (item, index, ind) => {
    const { questionAnswers } = this.state;

    const modifiedQuestionAnswers=[...questionAnswers];
    modifiedQuestionAnswers[index].answers.splice(ind, 1);

    // let modifiedmcqpoints = [];

    // questionAnswers[index].mcqDropDownOptions = modifiedmcqpoints;

    this.setState(
      { questionAnswers: modifiedQuestionAnswers }
    );
  };

  addOptions = (index) => {
    const { questionAnswers, mcqpoints } = this.state;

    if (questionAnswers[index].answers.length < 10) {
      const newOption = {
        label: "",
        points:  1,
        text:
          1 + "pt",
      };

      questionAnswers[index].answers.push(newOption);

      // Update the state with the new option
      this.setState({ questionAnswers: questionAnswers, mcqpoints: mcqpoints });
    }
  };

  deleteQuestionAns = async (index,questionId) => {

    if(questionId){
      this.setState({disable:true})
      try{
        const res = await fetchApi(DeleteQueAnsV2 + questionId,"DELETE");
        if(res.status === "failed"){
          toast.error(res?.data?.message)
        }else if(res.status === "success"){
          toast.success(res.data[0]);
          this.fetchSurveyLibraryDetails(this.state.libraryId, true);
        }
        this.setState({disable:false});
      }catch (error) {
        window.console.log(error);
        this.setState({disable:false});
      }
      
    }else{
      let questions = [...this.state.questionAnswers]
      questions.splice(index, 1);
      this.setState({questionAnswers:questions,disable:false});
    }




  };


  onSelectPoints = (name, /*value, key*/) => {
    this.setState({
      selectedSurveyPoint: name.value
    });
  };

  onSelectCategory = (name, /*value, key*/) => {
    this.setState({
      selectedSurveyCategory: name.name,
      selectedSurveyValue: name.id
    });
  };


  scrollToDiv = () => {
    if (this.targetDivRef.current) {
      this.targetDivRef.current.scrollIntoView({ behavior: "smooth" });
    }
    this.addNewQue();
  };

  onDragStart = (event, index) => {
    event.dataTransfer.setData("dragIndex", index);
  };

  onDragOver = (event) => {
    event.preventDefault();
  };

  onDrop = (event, dropIndex) => {
    const dragIndex = event.dataTransfer.getData("dragIndex");
    const { questionAnswers } = this.state;
    const items = [...questionAnswers];
    const draggedItem = items[dragIndex];

    // // Remove the item from the original position
    items.splice(dragIndex, 1);

    // // Insert the dragged item at the new position
    items.splice(dropIndex, 0, draggedItem);


    const payload = {
      survey_id: null,
      library_id: Number(this.state.libraryId),
      question_ids: items.map((item) => item.questionId)
    };

    // console.log("payload",payload);

    // questionAnswers = items;

    // this.setState({ questionAnswers: items });
    this.reorderQuestion(payload,items);
  };

  reorderQuestion = async(payload,queAns)=>{
    this.setState({disable:true})
    try{
      const res = await fetchApi(ReorderQueV2,"PUT",payload);
      if(res.status === "failed"){
        toast.error(res?.data?.message)
      }else if(res.status === "success"){
        toast.success(res.data[0]);
        this.setState({ questionAnswers: queAns });
        // this.props.history.push("/company/survey-library")
      }
      this.setState({disable:false});
    }catch (error) {
      window.console.log(error);
      this.setState({disable:false});
    }

  }


  areAllValuesNotEmpty(arr) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].question == "") {
        this.setState({ disable: true });
        // setDisable(true)
      } else {
        // setDisable(false)
        this.setState({ disable: false });
      }
    }
  }

  changeStep = (value) => {
    this.setState({ step: value });
  }

  convertSingleQuestionAnswer(data) {
    const getLikertOrOpinionScaleAnswers = (item, ans) => {
      if (item.questionType.name === "likert-scale") {
        return ans?.[0]?.points === 5
          ? likertScaleValues
          : likertScaleValues.map((item, index) => ({
            answer: item.answer,
            points: likertScaleValues[likertScaleValues.length - 1 - index].points,
          }));
      } else if (item.questionType.name === "opinion-scale") {
        const result = ans?.[0]?.points === 0
          ? opinionScaleValues
          : opinionScaleValues.map((item, index) => ({
            answer: opinionScaleValues[opinionScaleValues.length - 1 - index].points,
            points: opinionScaleValues[opinionScaleValues.length - 1 - index].points,
          }));
  
        // Update values and labels
        result.forEach((item, index) => {
          if (index === 0 || index === 10) {
            item.answer = ans[index === 0 ? 0 : 1].answer;
          } else {
            item.answer = index.toString();
          }
        });
        return result;
      }
      return ans; // Return the original answers if not likert-scale or opinion-scale
    };
  
    const questionType = data.questionType ? data.questionType.id : null;
    const acceptMultipleResponses =
      data.questionType && data.questionType.name === "multiple-choice"
        ? data.multipleMcqResponses
          ? 1
          : 0
        : 0;
  
    let answers = data.answers
      ? data.answers.map((answer) => ({
        answer: answer.label || answer.text,
        points: answer.points,
      }))
      : [];
  
    // Apply the Likert or Opinion Scale values if applicable
    answers = getLikertOrOpinionScaleAnswers(data, answers);
  
    return {
      question: data.question,
      question_type_id: questionType,
      accept_multiple_responses: acceptMultipleResponses,
      //   question_order: 1,
      answers: answers,
    };
  }

  createSurveyQues = (item, index, isFollowUp)=>{
    console.log("isFollowUp",isFollowUp);

    const{libraryId}=this.state;

    const{questionId,}=item;
    const payload = {question_id:questionId ? questionId : null,question_order:index+1,...this.convertSingleQuestionAnswer(item),library_id:Number(libraryId),survey_id : null};
    this.updateLibraryQuestions(payload,index);

  }

  updateLibraryQuestions =async(payload,index)=>{
    this.setState({disable:true})
    try{
      const res = await fetchApi(EditQuestionAnswersV2,"PUT",payload);
      if(res.status === "failed"){
        toast.error(res?.data?.message)
      }else if(res.status === "success"){
        toast.success(res.data[0]);
        const questionAnswers = [...this.state.questionAnswers];
        questionAnswers[index].isupdated = false;
        this.setState({ questionAnswers: questionAnswers });
        if(!payload.question_id){
          this.fetchSurveyLibraryDetails(this.state.libraryId, true);
        }
        
        // this.props.history.push("/company/survey-library")
      }
      this.setState({disable:false});
    }catch (error) {
      window.console.log(error);
      this.setState({disable:false});
    }
  }
  

  postSurveyLibrary = async(payload)=>{
    this.setState({disable:true})
    try{
      const res = await fetchApi(EditSurveyLibraryV2,"PUT",payload);
      if(res.status === "failed"){
        toast.error(res?.data?.message)
      }else if(res.status === "success"){
        toast.success(res.data[0]);
        this.props.history.push({pathname:"/company/surveys/library",state:{type: this.props.history?.location?.state?.type === "organization" ?"organization":null}});
      }
      this.setState({disable:false});
    }catch (error) {
      window.console.log(error);
      this.setState({disable:false});
    }
  }

  checkBase64(image) {
    if(image) {
      let arr = image.split('/');
      console.log("arr",arr);
      if(arr[0] !== 'survey-library-images' && arr[0] !== 'survey-images' && arr[0] !== 'survey-library-image' && arr[0] !== 'survey-image'&& arr[0] !== "survey-library") {
        console.log("hi image")
        return true;
      }
      else {
        return false;
      }
    }
  }

  onSubmit = ()=>{

    const{title,description,companyId,/*questionAnswers,*/imgSrc,selectedSurveyValue,libraryId,imageName}=this.state;

    let payload = {
      title:title,
      description:description,
      image:this.checkBase64(imgSrc) ? imgSrc : imageName,
      company_id:this.props.role === "ADMIN" ? null : companyId,
      category_id:selectedSurveyValue,
      library_id:libraryId,
    //   questions_answers:this.convertQuestionsAnswers(questionAnswers).questions_answers
    }

    // console.log("payload",payload)

    this.postSurveyLibrary(payload);

  }

  validateQuestionAnswers = () => {

    let finalQuestionAnswers =[...this.state.questionAnswers];
    let isValid = true;
    let errors = [];
  
    finalQuestionAnswers?.forEach((item, index) => {
      const { question, answers,isupdated, questionType } = item;
  
      // Validate question
      if (!question || question.trim() === ""||  !questionType.id) {
        isValid = false;
        errors.push(`Question at index ${index} is empty.`);
      }

      if(isupdated){
        isValid = false;
  
      }
  
      // Validate answers
      if(question?.questionType?.name != "short-answer"){
        answers?.forEach((answer, ansIndex) => {
          // console.log("answer + index", answer, "index",index);
          const { label, points, /*text*/ } = answer;
    
          if (!label || label.trim() === "") {
            // console.log("if answer + index", answer, "index",index);
            isValid = false;
            errors.push(`Label is empty in answer ${ansIndex + 1} of question ${index + 1}.`);
          }
          if (points.toString().trim() === "") {
            isValid = false;
            errors.push(`Points are empty in answer ${ansIndex + 1} of question ${index + 1}.`);
          }
        //   if (!text || text.trim() === "") {
        //     isValid = false;
        //     errors.push(`Text is empty in answer ${ansIndex + 1} of question ${index + 1}.`);
        //   }
        });
      }
    });

    return isValid;
  };

  render() {
    const {
      step,
      title,
      imgSrc,
      description,
      showQueReorderPopUp,
      questionAnswers,
      disable,
      selectedSurveyCategory,
      loading
    } = this.state;
    return (
      <React.Fragment>
        <>
          {loading ?<SkeletonLoder width={"1248px"} height={"100vh"} style={{margin:"20px auto"}}/> : (<>
            {step === 1
              ? this.stepOneFormData()
              : step === 2
                ? this.stepTwoForm() : null
            }
            {step === 1 ? (
              <ButtonContainer isPosition={true}>
                <Main>
                  <CommonButton
                    btnType={"squareIcon"}
                    onClick={() => this.updateStep(2)}
                    title={this.props.t("Next")}
                    styles={{color:"#007AB1"}}
                    disabled={!title ||!imgSrc ||!description}
                    nextIcon={NextArrows("white")}
                  />
                </Main>
              </ButtonContainer>
            ) : step === 2 && (
              <ButtonContainer isPosition={true}>
                <div className="wrapper">
                  <CommonButton
                    btnType={"squareIcon"}
                    styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center"}}
                    onClick={() => this.updateStep(1)}
                    title={this.props.t("Go Back")}
                    icon={Arrows()}
                  />
                  <CommonButton
                    btnType={"squareIcon"}
                    styles={{color:"#007AB1", marginAuto:'auto'}}
                    onClick={() => this.onSubmit()}
                    title={this.props.t("Save")}
                    nextIcon={LaunchEventIcon()}
                    disabled={disable||!selectedSurveyCategory || !this.validateQuestionAnswers() || questionAnswers.length < 2}
                  />
                </div>
              </ButtonContainer>
            )
            }
            {showQueReorderPopUp && (
              <QuestionReorderPopUp
                headerText={"Reorder Questions"}
                showModal={showQueReorderPopUp}
                companyId={this.state.companyId}
                updateStep={this.updateStep}
                onClose={() => {
                  this.setState({ showQueReorderPopUp: false });
                }}
                questions={questionAnswers}
                onDragStart={this.onDragStart}
                onDragOver={this.onDragOver}
                onDrop={this.onDrop}
              />
            )}
          </>)
          }
        </>
      </React.Fragment>
    );
  }
}

EditSurveyLibrary.propTypes = {
  history: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  t: PropTypes.func,
  companyInfo: PropTypes.object.isRequired,
  companyDetails: PropTypes.object,
  match:PropTypes.object
};

const mapStateToProps = (state) => ({
  userCompany: state.profileData.userCompany,
  companyDetails: state.profileData.companyDetails,
});


export default withRouter(connect(mapStateToProps , null)(withTranslation()(EditSurveyLibrary)));
